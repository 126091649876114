#root {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

html body {
  background-color: #eeeeee;
}

a {
  text-decoration: none;
  color: inherit;
}

